// Load Styles
import "../scss/main.scss";

// Import all of Bootstrap's JS
import * as bootstrap from "bootstrap";

// Wait for the DOM (Document Object Model) to be fully loaded

document.addEventListener("DOMContentLoaded", function () {
  // navigation scripts
  // TODO: check and improve accessibility
  const menuButton = document.getElementById("burger-menu");
  const menuContainer = document.getElementById("main-navigation");
  const searchInput = document.getElementById("search-input");

  // department of redundancy department

  menuButton?.addEventListener("click", event => {
    menuButton.classList.toggle("open");
    menuContainer.classList.toggle("fullscreenMenu");
  });

  searchInput?.addEventListener("focus", event => {
    menuContainer.classList.toggle("popoutSearch");
  });

  searchInput?.addEventListener("blur", event => {
    menuContainer.classList.toggle("popoutSearch");
  });
});

// keyboard actions

const Action = {
  toggleMenu() {
    menuButton.classList.toggle("open");
    menuContainer.classList.toggle("fullscreenMenu");
  }
};

const keyAction = {
  // o: { keydown: Action.toggleMenu }
};

const keyHandler = ev => {
  if (ev.repeat) return;
  if (!(ev.key in keyAction) || !(ev.type in keyAction[ev.key])) return;
  keyAction[ev.key][ev.type]();
};

["keydown", "keyup"].forEach(evType => {
  document.body.addEventListener(evType, keyHandler);
});
